var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('AllAssessmentHeader',{attrs:{"assessment_versions":_vm.assessment_versions,"assessment_version":_vm.assessment_version,"page":"cancelled","reset_btn":_vm.reset_btn},on:{"refresh":_vm.fetch_candidates,"update_al_ain_users_filter":_vm.fetch_candidates,"searched_user":_vm.searched_user,"update_assessment_version":_vm.update_assessment_version}}),(_vm.loading)?[_c('v-row',_vm._l((15),function(key){return _c('v-col',{key:key,attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('div',{staticClass:"base-card"},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)])}),1)]:(
      !_vm.loading && _vm.get_cancelled_assessment_candidates.results.length <= 0
    )?[_c('v-row',{staticClass:"mt-15 pt-15",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('div',{staticClass:"base-card"},[_c('DataNotFound',{attrs:{"title":_vm.get_title(1)}})],1)])],1)]:[_c('v-row',_vm._l((_vm.get_cancelled_assessment_candidates.results),function(candidate,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('AssessmentCard',{attrs:{"candidate":candidate.user,"assessment_id":_vm.assessment_id},on:{"view_profile":_vm.view_profile,"action":_vm.fetch_candidates}})],1)}),1)],_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[(_vm.loading)?[_c('v-skeleton-loader',{staticClass:"d-flex align-center mx-4 pagination-loader",attrs:{"tile":true,"type":"avatar@4"}})]:(
        !_vm.loading && _vm.get_cancelled_assessment_candidates.results.length > 0
      )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(_vm.get_cancelled_assessment_candidates.total / 12),"total-visible":6,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
              ? 'mdi-arrow-left'
              : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
              ? 'mdi-arrow-right'
              : 'mdi-arrow-left'},on:{"input":_vm.fetch_candidates},model:{value:(_vm.get_cancelled_assessment_candidates.page),callback:function ($$v) {_vm.$set(_vm.get_cancelled_assessment_candidates, "page", $$v)},expression:"get_cancelled_assessment_candidates.page"}})],1)]:_vm._e()],2),(_vm.on_boarding_invitation)?_c('OnBoardingInvitationCardForCancelled',{attrs:{"user":_vm.user_object},on:{"approve_decline_action":_vm.approve_decline_action,"close":_vm.on_close}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }